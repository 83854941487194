import { type ReactNode } from 'react';

import config from '../../config';
import { Header, type NavItem } from './Header';

export function AdminToolkitNav(props: {
  slackAdminToolEnabled?: boolean;
}): JSX.Element {
  const { slackAdminToolEnabled = config.misc.slackAdminToolEnabled } = props;

  const toolkitNavLinks = {
    Sessions: '/admin/toolkit/sessions',
    'Message Campaigns': {
      'Message Templates': '/admin/toolkit/message-templates',
      'Message Campaigns': '/admin/toolkit/message-campaigns',
    },
    'Drip Campaigns': '/admin/messages/campaigns',
    'Scheduled Games': '/admin/toolkit/events',
    AI: {
      'Prompt Templates': '/admin/prompt-templates',
      'Template Mappings': '/admin/prompt-templates/mappings',
      'Chat Conversations': '/admin/toolkit/ai-chat/threads',
      'Realtime Voice Chat': '/admin/voice-chat',
      'Training Prompt Profiles': '/admin/training/profiles',
      'Training Intelligence': '/admin/training/intelligence',
      'Training Media Tool': '/admin/training/media-tool',
      'Demo Agent': '/admin/training/agent/demo',
    },
    'Shared Media': '/admin/toolkit/shared-media',
    Products: {
      Products: '/admin/products',
      'OTP Price Table': '/admin/purchases/pricing-table',
      'OTP Activities': '/admin/purchases/activities',
      'HubSpot Products': '/admin/hubspot/products',
    },
    Misc: {
      'Team Relay Settings': '/admin/toolkit/team-relay-settings',
      'Cloud Hosting': '/admin/toolkit/cloud-hosting',
      DMG: '/admin/toolkit/dmg',
      'Test Media Upload': '/admin/toolkit/media-upload',
      Drawings: '/admin/toolkit/drawings',
      'Live Event Settings': '/admin/toolkit/live-event-settings',
      Categories: '/admin/toolkit/tags',
      'Markdown Preview': '/admin/toolkit/markdown',
      'Shared Scripts': '/admin/toolkit/shared-scripts',
      'Voice Over Locales': '/admin/toolkit/voice-over-locales',
      'Logo Color Extraction': '/admin/toolkit/logo-color-extraction',
      Slack: '/admin/toolkit/slack',
    } as { Slack?: string },
  } satisfies Record<string, NavItem>;

  if (!slackAdminToolEnabled) {
    delete toolkitNavLinks.Misc.Slack;
  }

  return <Header links={toolkitNavLinks} />;
}

export function Wrapper({ children }: { children?: ReactNode }): JSX.Element {
  return (
    <div className='w-full h-full'>
      <div className='py-10 px-10'>
        <AdminToolkitNav />
      </div>
      {children}
    </div>
  );
}
